<template>
  <div class="login" :style="{ height: height }">
    <div class="head">
      <div class="top flex-start">
        <div class="logo" @click="toHome">
          <img src="@/assets/logo.png" alt="" />
        </div>
        <div class="name" @click="toHome">墨库商城</div>
      </div>
    </div>
    <div class="box">
      <div class="login-box">
        <div class="title">账户登录</div>
        <el-form ref="login" :model="formData" :rules="rules">
          <el-form-item prop="user">
            <el-input
              v-model="formData.user"
              placeholder="请输入登录账户"
              prefix-icon="el-icon-user"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="formData.password"
              placeholder="请输入登录密码"
              prefix-icon="el-icon-unlock"
            ></el-input>
          </el-form-item>
          <el-form-item prop="code">
            <div class="code flex-between">
              <el-input
                v-model="formData.code"
                placeholder="验证码"
                style="width: 200px"
              ></el-input>
              <img src="@/assets/test.jpg" />
            </div>
          </el-form-item>
        </el-form>
        <div class="btn center" @click="login">登录</div>
        <div class="login-box-bottom">忘记密码？</div>
      </div>
    </div>
    <div class="foot">
      <p>Copyright © 2002-2021 www.wwgoto.com All Rights Reserved版权所有 中华商务贸易有限公司</p>
      <p><a target="_blank" href="https://beian.miit.gov.cn">粤ICP备11004666号</a> 出版经营许可证：新出发粤批字第 003 号</p>
      <p>本站所收录的作品、社区话题、用户评论、用户上传内容或图片等均属用户个人行为。如前述内容侵害您的权益，欢迎举报投诉，一经核实，立即删除，本站不承担任何责任</p>
      <p>地址：广州市荔湾区百花路花地好邻舍商业中心12层</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      height: window.innerHeight + "px",
      formData: {
        user: "",
        password: "",
        code: "",
      },
      rules: {
        user: [{ required: true, message: "请输入登录账户" }],
        password: [{ required: true, message: "请输入登录密码" }],
        code: [{ required: true, message: "请输入验证码" }],
      },
    };
  },
  methods: {
    login() {
      this.$refs.login.validate((v) => {
        if (v) {
          this.$router.push({
            name: "Home",
          });
        }
      });
    },
    toHome() {
      this.$router.push({
        name: "Home",
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  background-image: url("../../assets/images/loginbg.png");
  background-size: cover;
  background-position: center;
  background-color: rgba(41, 63, 81, 0.35);
  position: relative;
  .head {
    width: 100%;
    height: 88px;
    background: #ffffff;
    .top {
      width: 1200px;
      height: 88px;
      padding: 22px 0;
      margin: 0 auto;
      .logo {
        width: 44px;
        height: 44px;
        margin-right: 12px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .name {
        height: 44px;
        font-size: 30px;
        font-weight: 400;
        color: #202020;
        cursor: pointer;
      }
    }
  }
  .box {
    width: 1200px;
    margin: 0 auto;
    padding-top: 40px;
    display: flex;
    justify-content: flex-end;
    .login-box {
      width: 360px;
      height: 420px;
      background: rgba(255, 255, 255, 0.9);
      padding: 0 25px;
      .title {
        width: 100%;
        height: 50px;
        border-bottom: 1px solid #eaeaea;
        font-size: 20px;
        font-weight: bold;
        color: #202020;
        line-height: 50px;
        margin-bottom: 30px;
      }
      .code {
        width: 100%;
        img {
          width: 91px;
        }
      }
      .btn {
        width: 100%;
        height: 42px;
        font-size: 16px;
        font-weight: 600;
        color: #ffffff;
        background: #0ac9fa;
        letter-spacing: 3px;
        cursor: pointer;
        margin-top: 40px;
      }
      .login-box-bottom {
        width: 100%;
        border-top: 1px solid #eaeaea;
        margin-top: 30px;
        font-size: 14px;
        color: #939393;
        padding: 20px 0;
      }
    }
  }
  .foot {
    width: 100%;
    background: #ffffff;
    padding: 30px 0;
    font-size: 12px;
    font-weight: 400;
    color: #939393;
    line-height: 20px;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    a {
      text-decoration: none;
      color: #939393;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>